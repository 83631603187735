.card {
    border-radius: 0 !important;
    background-color: #fff;
    padding: -20px;
}
.card-text {
    color: white !important;
    font-family: $font-family-monospace;
}
.card-title {
    color: white !important;
    font-family: "PT Sans", sans-serif;
}
.card-grey {
    border-radius: 0 !important;
    background-color: #d6e9de;

    padding: 6px;
    border-left: 6px solid #1b485e;
}
.card-grey2 {
    border-radius: 0 !important;
    background-color: #d6e9de;
}
.card-light-green {
    border-radius: 0 !important;
    background-color: #e7f5ed;
    padding: 6px;
}

.card-grey-text {
    color: rgba(38, 38, 38, 1) !important;
    font-family: $font-family-monospace;
}
.card-grey-title {
    color: #1b485e;
    font-family: $font-family-sans-serif;
    font-weight: 800;
    font-size: 26px;
}
.logo-img-card {
    max-height: 65px;
    max-width: 300px;
}
.card-img-top-logo {
    border-radius: 0;
    height: 80px;
}
.card-img-top {
    width: 100%;
    border-radius: 0;
    height: 250px;
}
@include media-breakpoint-down(md) {
    .card-img-top {
        width: 100%;
        border-radius: 0;
        height: 180px;
    }
}
.card-img-float {
    float: left;
    padding-right: 35px;
    padding-bottom: 60px;
}
@include media-breakpoint-down(sm) {
    .card-img-float {
        float: none;
        padding-right: 35px;
        padding-bottom: 20px;
    }
}
.card-img-float-icon {
    float: left;
    padding-right: 35px;
    padding-bottom: 0px;
}
.card-horizontal {
    display: flex;
    flex: 1 1 auto;
}
@include media-breakpoint-down(md) {
    .card-horizontal {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
@include media-breakpoint-down(md) {
    .card-img-horizontal {
        width: 100%;
        border-radius: 0;
        height: 250px;
    }
}
.fa-check{
    padding-right: 20px;
    color: $custom-green-bg;
}
.fa-circle{
    padding-right: 20px;
    color: $custom-green-bg;
}
.badge-green {
    background-color: $custom-green-bg;
    border-radius: 0;
    font-size: 12px;
    line-height: 1;
    padding: .375rem .5625rem;
    font-weight: 700;
    border-radius: 10rem;
    margin-right: 20px;
    color: #ffffff;
}

.badge-green-big {
    background-color: $custom-green-bg;
    border-radius: 0;
    font-size: 22px;
    line-height: 1;
    padding: 1rem 1.4rem;
    font-weight: 600;
    border-radius: 10rem;
    color: #ffffff;
}