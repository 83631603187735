.hero {
    background-image: linear-gradient(rgba(2, 31, 47, 0.322) 100%, rgba(2, 31, 47, 0.712) 100%),
        url("/assets/img/header.png");
    max-height: 65rem;
    background-repeat: no-repeat;
    background-position: top right;
    background-size: auto;
    background-color: #021f2f;
}
@include media-breakpoint-down(md) {
    .hero {
        background-image: none;
        background: linear-gradient(to right, #021f2f, #002f66);
        min-height: 20rem;
    }
}
@include media-breakpoint-down(sm) {
    .hero {
        background-image: none;
        background: linear-gradient(to right, #021f2f, #002f66);
        min-height: 20rem;
    }
}

.hero h1 {
    color: white !important;
}
.hero p {
    color: white !important;
}
