p {
  font-weight: 400;
  font-size: 1rem;
  color: #262626;
  font-family: $font-family-monospace;
}
h1 {
  color: #3d5467 !important;
  font-weight: 800;
  font-family: $font-family-sans-serif;
}
h5 {
  color: white !important;
}
h3 {
  font-size: 23px;
  color: #1b485e !important;
}
h2 {
  font-size: 32px !important;
  color: #1b485e !important;
  font-weight: 800;
  font-family: $font-family-sans-serif;
}
h4 {
  font-size: 18px !important;
  color: rgba(61, 84, 103, 1) !important;
  font-weight: 700;
}
a {
  color: #54a976;
  font-weight: 700;
}
a:hover {
  text-decoration: underline;
  color: #54a976;
}
.subpage-header-text {
  font-size: 18px;
  font-weight: 600;
  color: #243b51;
}

%heading {
  margin-bottom: $spacer / 2;
  word-break: break-word;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  @extend %heading;
  &:not(.has-text-color) {
    color: inherit;
  }
}
@each $level, $size in $headings {
  #{$level} {
    font-size: calc(#{$size} * 0.8);
    line-height: $size;
    @include media-breakpoint-up("md") {
      font-size: $size;
      line-height: calc(#{$size} * 1.2);
    }
  }
}

.text-decoration-underline {
  text-decoration: underline;
}
.font-weight-bold {
  text-decoration: bold;
  color: #0f0e63;
}
