.panel-heading {
  background: #1b485e;
  padding: 25px 24px;
  position: relative;
  border-radius: 0px;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-chevron-down {
  position: absolute;
  right: 20px;
}
.panel-heading .panel-title a:hover {
  text-decoration: none;
}
.panel-heading .panel-title a {
  margin-right: 10px;
  display: block;
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;

}
@include media-breakpoint-down(md) {
  .panel-heading .panel-title a {
    margin-right: 10px;
    display: block;
    text-decoration: none;
    color: #ffffff;
    font-size: 18px;
  }
}
.panel-body {
  background: #d6e9de;
  padding: 18px 24px;
  margin-top: 5px;
  border-radius: 0px;
}
