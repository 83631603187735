@import "bootstrap/scss/mixins";

.container {
    max-width: 1200px;
}
.bg-container {
    background-color: #fff;
    width: 100%;
}
.bi {
    fill: currentColor;
}
.page-container {
    position: relative;
    min-height: 100vh;
}
html {
    position: relative;
    min-height: 100%;
    scroll-behavior: smooth;
}
body {
    margin-top: 60px;
    background-color: #f4f9f8;
}
.content-wrapper {
    margin-bottom: auto;
}

article {
    background-color: #fff;
    padding-top: 50px;
    padding-bottom: 100px;
}
.content-section h2 {
    text-align: center;
    margin: 0;
}
hr {
    border-bottom: 4px solid $custom-green-bg;
    border-top: 0px;
}
hr .dropdown {
    border: 1px solid $custom-green-bg;
}
