.sidebar-section {
    position: absolute;
    height: 100%;
    width: 100%;
}

.sidebar-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.sidebar-item a {
    color: #1B485E;
}

@include media-breakpoint-down(lg) {
    .sidebar-item {
        display: none;
    }
}
.make-me-sticky {
    top: 0;
    padding: 65px 15px;
}

.sidebar-item {
    text-align: left;

    .make-me-sticky-content {
        background: #F4F7F9;
        max-width: 100%;
        margin: 0 auto;
        padding: 50px 0 100px;
    }
}
.green-arrow-hover:hover{
    background-image: url(/assets/img/gren-arrow.png);
    opacity: 0.5;
}