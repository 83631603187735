footer {
    bottom: 0;
    width: 100%;
    height: 3.5rem;
    background-color: $custom-green-bg;
    position: absolute;
    left: 0;
    overflow: hidden;
}
footer p {
    color: white;
    font-weight: 700;
    font-family: $font-family-sans-serif;
}