.table thead > tr > th,
.table tbody > tr > th,
.table tfoot > tr > th,
.table thead > tr > td,
.table tbody > tr > td,
.table tfoot > tr > td {
    border-right: none;
    border-left: none;
    border-bottom: 2px solid #cccccc;
}
.table2 thead > tr > th,
.table2 tbody > tr > th,
.table2 tfoot > tr > th,
.table2 thead > tr > td,
.table2 tbody > tr > td,
.table2 tfoot > tr > td {
    border-top: 1px solid rgba(61, 84, 103, 0.25);
    padding-bottom: 15px;
    padding-top: 15px;
}
thead th {
    background-color: $custom-green-bg;
    font-size: 22px;
    font-weight: 600;
    color: white;
    text-align: center;
    padding: 10px !important;
    height: 57px;
    font-family: $font-family-monospace;

}
.table2{
    width: 100%;

}
.table2 thead th {
    background-color: $custom-green-bg;
    font-size: 15px;
    font-weight: 600;
    color: white;
    text-align: center;
    height: 20px;
    padding: 10px !important;
    font-family: $font-family-monospace;
}
.table2 td {
    font-family: $font-family-monospace;
    color: rgba(61, 84, 103, 1);
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
}

.table2 th {
    font-size: 15px;
}
th {
    font-weight: 600;
}
td {
    font-family: $font-family-monospace;
    color: rgba(61, 84, 103, 1);
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
}

@include media-breakpoint-down(sm) {
    .table-d-none {
        display: none;
    }
}

@include media-breakpoint-down(sm) {
    .img-responsive {
        max-width: 70px;
        height: auto;
    }
}
@include media-breakpoint-up(sm) {
    .img-responsive {
        max-width: 160px;
        height: auto;
    }
}
