.btn {
    font-family: $font-family-monospace;
    border: 3px solid #1b485e;
    border-radius: 25px;
    width: 183px;
    padding: 13px 8px 13px 8px;
    font-weight: 700;
    font-size: 18px;
    color: #1b485e;
    background: #fff;
}

.btn:hover {
    font-family: $font-family-monospace;
    border: 3px solid #1b485e;
    border-radius: 25px;
    padding: 13px 8px 13px 8px;
    font-weight: 700;
    font-size: 18px;
    color: #1b485e;
    background: rgba(117, 212, 155, 0.308);
}
@include media-breakpoint-down(sm) {
    .btn {
        font-family: $font-family-monospace;
        border-radius: 25px;
        width: 133px;
        padding: 10px 8px 10px 8px;
        font-weight: 700;
        font-size: 0.9rem;
        color: #1b485e;
    }
    .btn:hover {
        font-family: $font-family-monospace;
        border-radius: 25px;
        width: 133px;
        padding: 10px 8px 10px 8px;
        font-weight: 700;
        font-size: 0.9rem;
        background: rgba(117, 212, 155, 0.308);
    }
}

.btn-col {
    font-family: $font-family-monospace;
    border: 0;
    border-radius: 50px;
    width: 250px;
    padding: 13px 8px 13px 8px;
    font-weight: 700;
    font-size: 18px;
    color: #1b485e;
}

.btn-table {
    font-family: $font-family-monospace;
    border: 3px solid #1b485e;
    border-radius: 25px;
    width: 123px;
    padding: 8px 4px 8px 4px;
    font-weight: 700;
    font-size: 13px;
    color: #1b485e;
    background: #e5e5e5;
}

.btn-table:hover {
    font-family: $font-family-monospace;
    border: 3px solid #1b485e;
    border-radius: 25px;
    width: 123px;
    padding: 8px 4px 8px 4px;
    font-weight: 700;
    font-size: 13px;
    color: #1b485e;
    background: rgba(117, 212, 155, 0.308);
}
.btn-card {
    font-family: $font-family-monospace;
    border: 3px solid #1b485e;
    border-radius: 25px;
    width: 533px;
    padding: 13px 8px 13px 8px;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
    background: #1b485e;
}
.btn-card:hover {
    font-family: $font-family-monospace;
    border: 3px solid #1b485e;
    border-radius: 25px;
    padding: 13px 8px 13px 8px;
    font-weight: 600;
    font-size: 16px;
    color: #1b485e;
    background: rgba(117, 212, 155, 0.308);
}

@include media-breakpoint-down(sm) {
    .btn-card {
        font-family: $font-family-monospace;
        border: 3px solid #1b485e;
        border-radius: 25px;
        width: 320px;
        padding: 13px 8px 13px 8px;
        font-weight: 600;
        font-size: 14px;
        color: #ffffff;
        background: #1b485e;
    }
    .btn-card:hover {
        font-family: $font-family-monospace;
        border: 3px solid #1b485e;
        border-radius: 25px;
        width: 253px;
        padding: 13px 8px 13px 8px;
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        background: #1b485e;
    }
}
