@include media-breakpoint-up(xl) {
    .navbar {
        padding: 0px;
    }
}
.navbar {
    background-color: $custom-green-bg;
}
.nav-item {
    margin-left: 23px;
    font-family: $font-family-monospace;
    text-transform: none;
    font-size: 18px !important;
    color: white;
    text-decoration: none !important;
}
@include media-breakpoint-up(xl) {
    .nav-item {
        margin-right: 0px;
        margin-top: 5px;
    }
}
@include media-breakpoint-down(md) {
    .nav-item-border {
        border-bottom: 1px solid white;
    }
    .nav-item {
        margin-left: 0px;
        margin-top: 0px;
    }
}
.nav-link {
    text-decoration: none;
    color: white !important;
    padding: 0px;
}
.nav-link-active {
    color: #1b485e !important;
}
.nav-link:hover {
    text-decoration: underline;
}
.dropdown-item {
    text-decoration: none;
    color: #3d5467;
    font-weight: 700;
}
.dropdown-item-active {
    text-decoration: underline;
}
.dropdown-item:hover {
    text-decoration: underline;
    color: #3d5467;
}
.navbar-nav {
    margin-bottom: -15px;
}
.navbar-nav .nav-link:focus {
    color: white !important;
}
.navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0rem;
}
@include media-breakpoint-down(xl) {
    .navbar-mobile {
        margin-left: auto;
    }
}
@include media-breakpoint-up(lg) {
    .navbar .nav-item .dropdown-menu {
        display: none;
    }
    .navbar .nav-item:hover .dropdown-menu {
        display: block;
    }
    .navbar .nav-item .dropdown-menu {
        margin-top: -15px;
    }
}

.dropdown-menu .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}
.header {
    background-color: $custom-green-bg;
    height: 400px;
    padding-top: 25px;
}
.header-text {
    color: white;
}

.custom-navbar {
    background-color: $custom-green-bg;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

@include media-breakpoint-down(xl) {
    .header {
        display: none;
    }
}
main {
    top: 0;
}
